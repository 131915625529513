const ERROR_CONFIG = {
  dsn: CONFIG.sentryDsn,
  environment: CONFIG.environment,
  release: CONFIG.release,
};

// Emit the SPA error to the error hub
export const reportSpaError = (error: Error) => {
  const { dsn, environment, release } = ERROR_CONFIG;
  const detail = { error, dsn, environment, release };
  const errorEvent = new CustomEvent('on-spa-error', { detail });

  window.dispatchEvent(errorEvent);
};
