import React from 'react';
import { ApiKeysList } from '@components';
import { MainLayout } from '@layouts';

export const App = () => {
  return (
    <MainLayout>
      <ApiKeysList />
    </MainLayout>
  );
};
