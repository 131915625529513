import React, { useCallback, useMemo, useState } from 'react';
import { filterSortPaginate } from '@didomi/helpers';
import { useTableState } from '@didomi/helpers-react';
import { DidomiEmptyState, DidomiHintbox, DidomiPaginator } from '@didomi/ui-atoms-react';
import { useHasAccessPolicies, useSnackbar } from '@didomi/utility-react';
import { useApiKeysState } from '@hooks';
import { ApiKeyCreateSuccessModal, ConfirmActionModal, LoadingModal } from '@modals';
import { StatusFilterType } from '@types';
import { ACCESS_POLICIES_CONFIG, matchKeyStatus } from '@utils';
import { LOADING_MODAL_TEXT_PER_STATUS, SEARCH_FIELDS } from './ApiKeysListHelpers';
import { ApiKeysListToolbar } from '../ApiKeysListToolbar/ApiKeysListToolbar';
import { ApiKeysTable } from '../ApiKeysTable/ApiKeysTable';
import { Loader } from '../Loader/Loader';

export const ApiKeysList = () => {
  const { displaySnackbar } = useSnackbar();
  const [isConfirmCreateOpen, setIsConfirmCreateOpen] = useState(false);
  const [keyIdToDelete, setKeyIdToDelete] = useState<string>(null);
  const [newCreatedKey, setNewCreatedKey] = useState(null);
  const { hasAccess: isOrgSettingsEditor } = useHasAccessPolicies(
    ACCESS_POLICIES_CONFIG.ORG_SETTINGS_EDITOR,
  );

  const { items: apiKeys, addNewKey, deleteExistingKey, status } = useApiKeysState();
  const tableState = useTableState({
    defaultSorting: { field: 'created_at', dir: 'desc' },
    defaultLimit: 5,
    defaultFilters: { status: StatusFilterType.ALL },
  });

  const filterByType = useCallback(matchKeyStatus(tableState.filters.status as StatusFilterType), [
    tableState.filters.status,
  ]);

  const { data: apiKeysToDisplay, total: paginationTotal } = useMemo(
    () =>
      filterSortPaginate(apiKeys, {
        search: tableState.search,
        sortConfig: tableState.sortConfig,
        limit: tableState.limit,
        page: tableState.page,
        searchFields: SEARCH_FIELDS,
        filters: [filterByType],
      }),
    [tableState, apiKeys, filterByType],
  );

  const handleCreateConfirm = async () => {
    setIsConfirmCreateOpen(false);
    const { data, error } = await addNewKey();

    /* istanbul ignore else */
    if (data) {
      setNewCreatedKey(data);
      /* istanbul ignore else */
    } else {
      displaySnackbar(error, { variant: 'error' });
    }
  };

  const handleDeleteConfirm = async () => {
    const { error } = await deleteExistingKey(keyIdToDelete);
    setKeyIdToDelete(null);

    /* istanbul ignore if */
    if (error) {
      displaySnackbar(error, { variant: 'error' });
    } else {
      displaySnackbar('The API Key has been deleted', { icon: 'success-small' });
    }
  };

  const isEmptyDb = apiKeys.length === 0;
  const isEmptyResults = apiKeysToDisplay.length === 0;

  return (
    <section className="flex flex-col">
      <ApiKeysListToolbar
        numberOfResults={paginationTotal}
        isCreateVisible={isOrgSettingsEditor}
        onSearchChange={tableState.changeSearch}
        onFilterChange={status => tableState.changeFilters({ status })}
        onCreateClicked={() => setIsConfirmCreateOpen(true)}
      />

      {!isEmptyResults && status !== 'loading' && (
        <>
          <ApiKeysTable
            apiKeys={apiKeysToDisplay}
            sortConfig={tableState.sortConfig}
            search={tableState.search}
            isDeleteVisible={isOrgSettingsEditor}
            onSortChange={tableState.changeSorting}
            onDeleteClicked={setKeyIdToDelete}
          />

          <DidomiPaginator
            data-testid="api-keys-paginator"
            className="self-end"
            page={tableState.page}
            itemCount={paginationTotal}
            size={tableState.limit as any}
            onPageSizeChange={tableState.changeLimit}
            onPageChange={tableState.changePage}
          />
        </>
      )}

      {/* Empty State */}
      {isEmptyResults && status !== 'loading' && (
        <DidomiEmptyState
          illustration-name="traces-no-result-found"
          actionName={isOrgSettingsEditor ? 'Add a new Private API Key' : ''}
          actionIconRight={isOrgSettingsEditor ? 'new-create' : ''}
          onActionClick={() => setIsConfirmCreateOpen(true)}
        >
          <div slot="title">It&apos;s empty here!</div>
          {isEmptyDb ? 'You do not have API Keys yet.' : 'No results'}
        </DidomiEmptyState>
      )}

      {/* Loading State */}
      {status === 'loading' && <Loader />}

      {/* Modals */}
      <ConfirmActionModal
        isOpen={isConfirmCreateOpen}
        titleText="Add a new Private API Key"
        descriptionText="Create a new API key to manage your Didomi account and consents from a back-end application calling 
the Didomi API."
        primaryButtonText="Generate a new key"
        onCancel={() => setIsConfirmCreateOpen(false)}
        onConfirm={() => handleCreateConfirm()}
      >
        <DidomiHintbox className="text-left" variant="warning" iconName="warning">
          You do not need to create an API key for collecting consents on your websites or mobile
          apps through our Web or Mobile SDKs.
        </DidomiHintbox>
      </ConfirmActionModal>

      <ConfirmActionModal
        isOpen={!!keyIdToDelete}
        headerIllustration="warning-modal"
        titleText="Are you sure you want to delete this API Key?"
        descriptionText="Make sure that no application is using the API key before deleting it. The key will be permanently deleted and will stop functioning immediately. Applications that use this API key for authenticating requests to the Didomi API will stop functioning."
        primaryButtonText="Delete"
        onCancel={() => setKeyIdToDelete(null)}
        onConfirm={handleDeleteConfirm}
      />

      <LoadingModal
        isOpen={status === 'saving' || status === 'deleting'}
        title={LOADING_MODAL_TEXT_PER_STATUS[status]}
      />
      <ApiKeyCreateSuccessModal
        isOpen={!!newCreatedKey}
        apiKey={newCreatedKey}
        onClose={() => setNewCreatedKey(null)}
      />
    </section>
  );
};
