import React from 'react';
import {
  DidomiButton,
  DidomiCopyText,
  DidomiModal,
  DidomiModalActions,
  DidomiModalContent,
  DidomiModalHeader,
} from '@didomi/ui-atoms-react';
import { useSnackbar } from '@didomi/utility-react';
import { tx } from '@twind/core';

interface ApiKeyCreateSuccessModalProps {
  isOpen: boolean;
  apiKey?: { id: string; secret: string };
  onClose: () => void;
}

export const ApiKeyCreateSuccessModal = ({
  isOpen,
  apiKey,
  onClose,
}: ApiKeyCreateSuccessModalProps) => {
  const { displaySnackbar } = useSnackbar();

  return (
    <DidomiModal isOpen={isOpen} variant="slim" permanent onClose={onClose}>
      <DidomiModalHeader
        illustrationName="success"
        modalTitle="A new API key was created!"
        modalDescription="This is the only time that the key secret can be viewed. You cannot recover it later. 
However, you can create new API keys at any time."
        modalSubTitle="Well done!"
      />

      <DidomiModalContent className={tx('w-full text-left')}>
        {apiKey && (
          <>
            <div className={tx('text-body-small text-primary-blue-6 font-semibold pl-xs')}>
              API Key ID
            </div>
            <DidomiCopyText
              data-testid="copy-api-key-id"
              aria-label="Copy Api Key ID"
              className={tx('mb-xxs')}
              variation="basic-outline"
              text={apiKey?.id}
              isIconPersistent
              truncate
              onTextCopied={() => displaySnackbar('Copied', { icon: 'success-small' })}
            >
              {apiKey?.id}
            </DidomiCopyText>

            <div className={tx('text-body-small text-primary-blue-6 font-semibold pl-xs')}>
              API Key Secret
            </div>
            <DidomiCopyText
              data-testid="copy-api-key-secret"
              aria-label="Copy Api Key Secret"
              variation="basic-outline"
              text={apiKey?.secret}
              isIconPersistent
              truncate
              onTextCopied={() => displaySnackbar('Copied', { icon: 'success-small' })}
            >
              {apiKey?.secret}
            </DidomiCopyText>
          </>
        )}
      </DidomiModalContent>

      <DidomiModalActions>
        <DidomiButton onClick={onClose}>Close</DidomiButton>
      </DidomiModalActions>
    </DidomiModal>
  );
};
