import React from 'react';
import { DidomiErrorState } from '@didomi/ui-atoms-react';

export const ErrorState = () => (
  <div className="h-full p-l">
    <DidomiErrorState illustrationName="content-loading-error" className="h-full">
      <div slot="title">There seems to be an error</div>
      <div slot="description">
        Please try refreshing the page or contact{' '}
        <a className="underline" href="mailto:support@didomi.io?subject=API Keys Error">
          support@didomi.io
        </a>{' '}
        for further assistance.
      </div>
    </DidomiErrorState>
  </div>
);
