import React from 'react';
import { SortConfig } from '@didomi/helpers';
import {
  DidomiTable,
  DidomiTableHeading,
  DidomiTableHeaderRow,
  DidomiTableTh,
  DidomiTableBody,
  DidomiTableRow,
  DidomiTableTd,
  DidomiTooltip,
  DidomiIconButton,
} from '@didomi/ui-atoms-react';
import { ApiKey } from '@types';
import { formatDate } from '@utils';
import { TextWithHighlighterAndTooltip } from '../TextWithHighlighterAndTooltip/TextWithHighlighterAndTooltip';

interface ApiKeysTableProps {
  apiKeys: ApiKey[];
  sortConfig: SortConfig;
  search: string;
  isDeleteVisible?: boolean;
  onSortChange: (sortConfig: CustomEvent<{ sortId: string; direction: 'asc' | 'desc' }>) => void;
  onDeleteClicked: (keyId: string) => void;
}

export const ApiKeysTable = ({
  apiKeys,
  sortConfig,
  search,
  isDeleteVisible = true,
  onSortChange,
  onDeleteClicked,
}: ApiKeysTableProps) => (
  <DidomiTable
    className="mb-xs"
    sortBy={sortConfig.field}
    sortDirection={sortConfig.dir}
    onSortChange={onSortChange}
    sortable
  >
    <DidomiTableHeading>
      <DidomiTableHeaderRow>
        <DidomiTableTh sortId="id" style={{ flexBasis: 80 }}>
          KEY ID
        </DidomiTableTh>
        <DidomiTableTh sortId="created_at">CREATION DATE</DidomiTableTh>
        <DidomiTableTh>STATUS</DidomiTableTh>

        {/* Actions Column */}
        <DidomiTableTh></DidomiTableTh>
      </DidomiTableHeaderRow>
    </DidomiTableHeading>

    <DidomiTableBody>
      {/** There is a bug in the ui-library that breaks re-rendering the rows dynamically - wrapping the content in a div fixes the issue */}
      <div>
        {apiKeys.map(key => (
          <DidomiTableRow key={key.id}>
            <DidomiTableTd style={{ flexBasis: 80 }} data-testid="keyId-cell">
              <TextWithHighlighterAndTooltip searchText={search} text={key.id} />
            </DidomiTableTd>
            <DidomiTableTd>
              <TextWithHighlighterAndTooltip
                searchText={search}
                text={formatDate(key.created_at)}
              />
            </DidomiTableTd>
            <DidomiTableTd>{key.enabled ? 'Enabled' : 'Disabled'}</DidomiTableTd>

            <DidomiTableTd cellAlign="right">
              {isDeleteVisible && (
                <DidomiTooltip content="Delete key" placement="top">
                  <DidomiIconButton
                    aria-label="Delete key"
                    type="button"
                    variant="rounded"
                    icon="delete"
                    onClick={() => onDeleteClicked(key.id)}
                  />
                </DidomiTooltip>
              )}
            </DidomiTableTd>
          </DidomiTableRow>
        ))}
      </div>
    </DidomiTableBody>
  </DidomiTable>
);
