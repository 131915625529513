import React from 'react';
import { DidomiFeatureHeader } from '@didomi/ui-atoms-react';

type MainLayoutProps = {
  children: React.ReactChild;
};

export const MainLayout = ({ children }: MainLayoutProps) => {
  return (
    <section className="p-l pb-unsafe-bottom-space">
      <DidomiFeatureHeader titleText="Private API Keys" className="w-full block mb-s">
        <div slot="description">
          Private API keys allow your back-end applications to access the Didomi API for managing
          your account and consents.
        </div>
      </DidomiFeatureHeader>

      <div>{children}</div>
    </section>
  );
};
