import { useCallback, useEffect, useState } from 'react';
import { PaginatedResponse } from '@didomi/helpers';
import { useActiveOrganization } from '@didomi/utility-react';
import { ApiKey } from '@types';
import { useAxiosWithLogOut } from './useAxiosWithInterceptors.hook';

const API_URL = CONFIG.environmentConfig.apiBaseUrl;

type ApiKeysState = {
  isLoading: boolean;
  error: unknown;
  data: ApiKey[];
};

// Fetch all api keys for the current organization
export const useApiKeys = () => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();

  const [requestState, setRequestState] = useState<ApiKeysState>({
    isLoading: true,
    data: [],
    error: null,
  });

  const getData = useCallback(async () => {
    try {
      const apiKeysResponse = await axiosWithInterceptors.get<PaginatedResponse<ApiKey>>(
        `${API_URL}keys?$limit=5000&organization_id=${organizationId}`,
      );

      const apiKeys = apiKeysResponse.data.data;

      setRequestState({ isLoading: false, error: null, data: apiKeys });
    } catch (error) {
      setRequestState({ isLoading: false, error, data: [] });
    }
  }, [axiosWithInterceptors, organizationId]);

  useEffect(() => {
    getData();
  }, [getData]);

  return requestState;
};
