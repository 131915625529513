import { useCallback, useState } from 'react';
import { getApiErrorText } from '@utils';
import { useAxiosWithLogOut } from './useAxiosWithInterceptors.hook';

const API_URL = CONFIG.environmentConfig.apiBaseUrl;

export const useDeleteApiKey = () => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const [isDeleting, setIsDeleting] = useState(false);

  const deleteKey = useCallback(
    async (keyId: string) => {
      setIsDeleting(true);

      try {
        const deleteKeyResponse = await axiosWithInterceptors.delete(`${API_URL}keys/${keyId}`);

        return {
          data: deleteKeyResponse.data,
          error: null,
        };
      } catch (error) {
        return {
          data: null,
          error: 'There was an error deleting the key: ' + getApiErrorText(error, 'unknown error'),
        };
      } finally {
        setIsDeleting(false);
      }
    },
    [axiosWithInterceptors],
  );

  return {
    deleteKey,
    isDeleting,
  };
};
