import { useEffect, useMemo, useState } from 'react';
import { ApiKey } from '@types';
import { useAddApiKey } from './useAddApiKey.hook';
import { useApiKeys } from './useApiKeys.hook';
import { useDeleteApiKey } from './useDeleteApiKey.hook';

export type Status = 'idle' | 'loading' | 'saving' | 'deleting';

export const useApiKeysState = () => {
  const { data: loadedItems, isLoading } = useApiKeys();
  const { add, isSaving } = useAddApiKey();
  const { deleteKey, isDeleting } = useDeleteApiKey();

  const [apiKeysItems, setApiKeysItems] = useState<ApiKey[]>([]);

  const status: Status = useMemo(() => {
    if (isLoading) return 'loading';
    if (isSaving) return 'saving';
    if (isDeleting) return 'deleting';
    return 'idle';
  }, [isLoading, isSaving, isDeleting]);

  useEffect(() => {
    setApiKeysItems(loadedItems);
  }, [loadedItems]);

  const addNewKey = async () => {
    const { data, error } = await add();

    if (data) {
      setApiKeysItems(apiKeysItems.concat(data));
    }

    return { data, error };
  };

  const deleteExistingKey = async (keyId: string) => {
    const { data, error } = await deleteKey(keyId);

    if (data) {
      setApiKeysItems(apiKeysItems.filter(key => key.id !== keyId));
    }

    return { data, error };
  };

  return {
    items: apiKeysItems,
    addNewKey,
    deleteExistingKey,
    status,
  };
};
