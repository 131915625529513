import React from 'react';
import {
  DidomiButton,
  DidomiModal,
  DidomiModalHeader,
  DidomiModalActions,
  DidomiButtonGroup,
  DidomiModalContent,
} from '@didomi/ui-atoms-react';
import { Illustrations } from '@didomi/ui-foundation/dist/svg-types';
import { tx } from '@twind/core';

type ConfirmActionModalProps = {
  isOpen: boolean;
  headerIllustration?: Illustrations;
  titleText: string;
  descriptionText?: string;
  primaryButtonText?: string;
  children?: React.ReactChild;
  onCancel?: () => void;
  onConfirm?: () => void;
};

/**
 * Generic modal for action confirmation
 */
export const ConfirmActionModal = ({
  isOpen,
  headerIllustration,
  titleText,
  descriptionText,
  primaryButtonText,
  children,
  onCancel,
  onConfirm,
}: ConfirmActionModalProps) => {
  return (
    <DidomiModal isOpen={isOpen} permanent variant="slim">
      <DidomiModalHeader
        modalTitle={titleText}
        illustrationName={headerIllustration}
        modalDescription={descriptionText}
      />

      <DidomiModalContent>
        <div className={tx('text-body-small text-primary-blue-6')}>{children}</div>
      </DidomiModalContent>

      <DidomiModalActions>
        <DidomiButtonGroup>
          <DidomiButton variant="secondary" onClick={onCancel}>
            Cancel
          </DidomiButton>
          <DidomiButton onClick={onConfirm}>{primaryButtonText}</DidomiButton>
        </DidomiButtonGroup>
      </DidomiModalActions>
    </DidomiModal>
  );
};
