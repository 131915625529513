import { formatDate } from '@utils';

export const SEARCH_FIELDS = [
  { field: 'id' },
  { field: 'created_at', transform: d => formatDate(d) },
];

export const LOADING_MODAL_TEXT_PER_STATUS = {
  saving: 'We are generating your private API key...',
  deleting: 'We are deleting your private API key...',
};
