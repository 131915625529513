import { useCallback, useState } from 'react';
import { useActiveOrganization } from '@didomi/utility-react';
import { ApiKey } from '@types';
import { getApiErrorText } from '@utils';
import { useAxiosWithLogOut } from './useAxiosWithInterceptors.hook';

const API_URL = CONFIG.environmentConfig.apiBaseUrl;

export const useAddApiKey = () => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();
  const [isSaving, setIsSaving] = useState(false);

  const add = useCallback(async () => {
    setIsSaving(true);

    try {
      const createKeyResponse = await axiosWithInterceptors.post<ApiKey>(`${API_URL}keys`, {
        organization_id: organizationId,
      });

      return {
        data: createKeyResponse.data,
        error: null,
      };
    } catch (error) {
      return {
        data: null,
        error: 'There was an error generating the key: ' + getApiErrorText(error, 'unknown error'),
      };
    } finally {
      setIsSaving(false);
    }
  }, [axiosWithInterceptors, organizationId]);

  return {
    add,
    isSaving,
  };
};
