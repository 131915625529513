import { ApiKey, StatusFilterType } from '@types';

export const matchKeyStatus = (status: StatusFilterType) => (key: ApiKey) => {
  if (status === StatusFilterType.ENABLED) {
    return key.enabled;
  } else if (status === StatusFilterType.DISABLED) {
    return !key.enabled;
  } else {
    return true;
  }
};
