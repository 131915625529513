import React, { useMemo, useState } from 'react';
import { getCounterText } from '@didomi/helpers';
import { DidomiFiltersBarCustomEvent } from '@didomi/ui-atoms';
import { DidomiButton, DidomiFiltersBar } from '@didomi/ui-atoms-react';
import { StatusFilterType } from '@types';

type ApiKeysListToolbarProps = {
  numberOfResults: number;
  isCreateVisible?: boolean;
  onSearchChange?: (value: string) => void;
  onFilterChange?: (value: StatusFilterType) => void;
  onCreateClicked?: () => void;
};

export const ApiKeysListToolbar = ({
  numberOfResults,
  isCreateVisible = true,
  onSearchChange,
  onFilterChange,
  onCreateClicked,
}: ApiKeysListToolbarProps) => {
  const [statusFilter, setStatusFilter] = useState<StatusFilterType>();
  const resultsText = getCounterText(numberOfResults, 'key');

  const handleSearchChange = (e: CustomEvent<string>) => onSearchChange(e.detail);
  const handleFilterChange = (
    e: DidomiFiltersBarCustomEvent<{ key: string; newValue: StatusFilterType }>,
  ) => {
    setStatusFilter(e.detail.newValue);
    onFilterChange(e.detail.newValue);
  };

  const filters = useMemo(
    () => ({
      filterByStatus: {
        placeholder: 'All keys',
        value: statusFilter,
        options: [
          { value: '', label: 'All keys' },
          { value: StatusFilterType.ENABLED, label: 'Enabled' },
          { value: StatusFilterType.DISABLED, label: 'Disabled' },
        ],
      },
    }),
    [statusFilter],
  );

  return (
    <section className="flex items-center gap-4 mb-4">
      <div className="text-body-small text-primary-blue-6 font-semibold mr-auto">{resultsText}</div>

      <DidomiFiltersBar
        data-testid="filters-bar"
        searchValue=""
        showSearch
        collapsibleFilters={false}
        filters={filters}
        onSearchTextChange={handleSearchChange}
        onFilterValueChange={handleFilterChange}
      />

      {isCreateVisible && (
        <DidomiButton iconRight="new-create" onClick={onCreateClicked}>
          Add a new Private API Key
        </DidomiButton>
      )}
    </section>
  );
};
